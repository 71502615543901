import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import Header from "./header";

import "@sass/main.scss";
import HeroAltAlt from "./index/hero-alt-alt";

function IndexLayoutAlt({ children }) {
  return (
    <div className="site">
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/agn5wiq.css" />
      </Helmet>

      <div className="flex h-screen self-center flex-col">
        <div>
          <Header />
        </div>
        <div className="flex-grow relative flex w-full">
          <HeroAltAlt />
        </div>
      </div>

      <div className="main">{children}</div>
    </div>
  );
}

IndexLayoutAlt.propTypes = {
  children: PropTypes.node.isRequired
};

export default IndexLayoutAlt;
