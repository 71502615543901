import React from "react";

const DrinkItem = ({ post }) => (
  <div className="mb-3 w-full md:w-1/2 px-4">
    <header>
      <h3 className="text-2xl lowercase mb-2">{post.node.title}</h3>
    </header>
    <div className="text-lg">
      {post.node.acf.drink_type} + {post.node.acf.drink_description}
    </div>
  </div>
);

export default DrinkItem;
