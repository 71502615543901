import React from "react";

const FoodItem = ({ post }) => (
  <div className="mb-3 w-full md:w-1/2 px-4">
    <header className="flex flex-row justify-between items-center">
      <h3 className="lowercase text-2xl mb-2">{post.node.title}</h3>
      <div className="">
        <span>{post.node.acf.price}</span>
      </div>
    </header>
    <div className="text-lg">{post.node.acf.description}</div>
  </div>
);

export default FoodItem;
