import React from "react";
import Oppettider from "../common/Oppettider";

export default function Contact() {
  return (
    <div className="bg-orange">
      <div className="flex flex-col md:flex-row px-4 py-8 mt-8 max-w-6xl mx-auto items-center text-center">
        <div className="w-full md:w-1/3 p-2">
          <a
            target="_blank"
            rel="nofollow"
            href="https://www.google.se/maps/place/SOI+29/@55.5942579,13.0058297,17z/data=!3m1!4b1!4m5!3m4!1s0x4653a15b9612f849:0xbf22ffbaf855a789!8m2!3d55.5942549!4d13.0080184"
          >
            Bergsgatan 29, 214 22 Malm&ouml;
          </a>
        </div>
        <div className="w-full md:w-1/3 p-2 oppettider">
          <Oppettider />
        </div>
        <div className="w-full md:w-1/3 p-2">
          <a href="tel:+4640292944">040 29 29 44</a>
          <br />
          contact@soi29thaikitchen.com
        </div>
      </div>
      <div className="pb-2 flex justify-center -mt-4">
        <div className="text-sm pr-2 -py-2 text-yellow-700">
          Site by{" "}
          <span className="font-bold">
            <a href="https://www.olleburlin.se">Olle Burlin</a>
          </span>
        </div>
      </div>
    </div>
  );
}
