import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function Oppettider() {
  const data = useStaticQuery(graphql`
    {
      wordpressPage(slug: { eq: "oppettider" }) {
        slug
        content
        id
      }
    }
  `);
  const content = data.wordpressPage.content;
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
