import React from "react";
import FoodItem from "@components/menu/FoodItem";
import DrinkItem from "@components/menu/DrinkItem";

import { useStaticQuery, graphql } from "gatsby";

function Menu() {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWordpressWpFood {
        edges {
          node {
            id
            title
            acf {
              description
              price
            }
          }
        }
      }
      allWordpressWpDrinks {
        edges {
          node {
            title
            acf {
              drink_type
              drink_description
            }
          }
        }
      }
    }
  `);

  return (
    <div className="index-menu" id="menu">
      <section className="menu text-lg flex flex-col max-w-6xl mx-auto px-2 md:px-4 mb-0">
        {/* Food */}
        <div className="p-4">
          <h2 className="text-4xl lowercase border-b-4 border-black border-double font-bold mb-4">
            Meny
          </h2>
          <div className="md:flex md:flex-wrap -mx-4">
            {data.allWordpressWpFood.edges.map((post) => {
              return <FoodItem key={post.node.id} post={post} />;
            })}
          </div>
        </div>
        <div className="text-center px-4 my-4">
         <p>Ris 25kr/p<br />
Allergier? Prata med oss!</p>
        </div>
        {/* Special Menu */}

        
        {/* Drinks */}
      </section>
    </div>
  );
}

export default Menu;
