import React from "react";
import BackgroundSlider from "gatsby-image-background-slider";
import { useStaticQuery, graphql } from "gatsby";

export default function DesktopSlider() {
  return (
    <div className="">
      <BackgroundSlider
        duration={8}
        images={[`2.jpg`, `1.jpg`, `3.jpg`, `4.jpg`]} // delay before the first transition (if left at 0, the first image will be skipped initially)
        initDelay={2} // transition duration between images
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        transition={4}
      />
    </div>
  );
}
