import React from "react";
import DesktopSlider from "@components/index/desktop-slider";
import Logo from "@components/common/logo";

export default function HeroAltAlt() {
  return (
    <div className="hero flex flex-col self-center h-full w-full">
      <DesktopSlider />
      <div className="self-center my-auto text-teal-100 w-full hero-inner">
        <div className="mx-auto max-w-2xl">
          <div className="hero-logo max-w-md px-8 mb-4 text-center mx-auto block">
            <Logo fill="" className="fill-current" />
          </div>

          <div className="max-w-2xl mx-auto px-2 text-center">
            <h1 className="md:text-6xl text-2xl shadow-text">
              thai street food &amp; bar
            </h1>
          </div>
          <nav className="block md:hidden text-center mt-4">
            <span className="bg-teal-100 inline-block pt-4 pb-3 uppercase rounded px-3 leading-none">
              <span className="purple text-xl">
                <a
                  href="https://book.easytablebooking.com/book/?id=51d29&lang=auto"
                  title="Book"
                  target="_blank"
                  rel="noopener"
                >
                  Reservations
                </a>
              </span>
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
}
