import React from "react";

import IndexLayoutAlt from "@components/index-layout-alt";
import Contact from "@components/index/contact";
import SEO from "@components/seo";
import Menu from "@components/menu/menu";
function IndexPage() {
  return (
    <IndexLayoutAlt>
      <SEO
        keywords={[`Soi 29`, `Thai`, `Restaurant`, `Malmö`]}
        title="SOI 29 - Thai street food &amp; bar"
      />
      <div>
        <div className="max-w-2xl mx-auto text-center p-2 pt-12 pb-4 md:pb-2">
          <p className="text-2xl mb-0">"Malmö's best asian restaurant 2019"</p>
          <p className="text-xl pt-2 md:pt-4">- Sydsvenskan</p>
        </div>
      </div>

      {/* <div className="px-2 my-8">
        <Menu />
      </div> */}
      <Contact />
    </IndexLayoutAlt>
  );
}

export default IndexPage;
